<template>
  <el-dialog v-loading="loading" visible :before-close="close" width="85%" top="2%">
    <template #title>
      <div class="d-flex align-items-center">
        <AdditionalNamesIcon width="14" height="14" :class="$direction === 'rtl' ? 'ms-2' : 'me-2'" />
        <h2>{{ $t('tasks.task.product.title') }}</h2>
      </div>
    </template>
    <p class="mb-4">{{ $t('tasks.task.product.productToReview') }}</p>
    <div class="card p-1 mb-5">
      <Table :data="productToReviewTableData" :columns="columns" expandable>
        <template #cell-name="{ rowData: { name, references } }">
          <template v-if="name && references.length">
            <div class="d-flex align-items-center">
              <AdditionalNamesPopOver
                v-if="references.length > 1"
                :icon-size="12"
                :references="references"
                :class="$direction === 'rtl' ? 'ms-1' : 'me-1'"
              />
              {{ name }}
            </div>
          </template>
        </template>
        <template #cell-currentTerm="{ rowData: { currentTerm } }">
          <template v-if="!!currentTerm">
            <template v-if="currentTerm.price !== null">
              <p>{{ formatMoney(currentTerm.netPrice) }}</p>
              <small v-if="currentTerm.discount" class="d-block text-muted">
                ({{ `${formatMoney(currentTerm.price)}, ${formatPercent(currentTerm.discount)}` }})
              </small>
            </template>
            <template v-if="currentTerm.priceIndexName !== null"> </template>
          </template>
          <Tag v-else>{{ $t('terms.noTerm') }}</Tag>
        </template>
        <template #cell-lastOrderPrice="{ rowData: { lastOrderPrice } }">
          <template v-if="!!lastOrderPrice">
            <template v-if="lastOrderPrice && lastOrderPrice.price !== null">
              <p>{{ formatMoneyShekels(lastOrderPrice.netPrice) }}</p>
              <small v-if="lastOrderPrice.discount" class="d-block text-muted">
                ({{ `${formatMoneyShekels(lastOrderPrice.price)}, ${formatPercent(lastOrderPrice.discount)}` }})
              </small>
            </template>
          </template>
          <template v-else>-</template>
        </template>
        <template #cell-lastOrderDate="{ rowData: { lastOrderDate } }">
          {{ formatDate(lastOrderDate) }}
        </template>
        <template #header-decisionTaking>{{ `` }}</template>
      </Table>
    </div>
    <p class="mb-4">{{ $t('tasks.task.product.defineRelation') }}</p>
    <div class="card mb-5">
      <el-form ref="form" :model="formModel" :show-message="false">
        <Table :data="transformedSimilarProducts" :columns="columns" expandable>
          <template #expandable-content="{ rowData: { subTable } }">
            <table class="w-100 sub-table">
              <tbody>
                <tr v-for="row in subTable" :key="row.restaurantName">
                  <td class="bg-secondary"></td>
                  <td class="bg-secondary" :class="$direction === 'ltr' ? 'ms-4' : 'me-4'">
                    {{ row.restaurantName }}
                  </td>
                  <td class="bg-secondary">{{ row.lastOrderDate ? formatDate(row.lastOrderDate) : '-' }}</td>
                  <td class="bg-secondary">
                    <template v-if="!isNil(row.lastOrderPrice)">
                      <template v-if="!isNil(row.lastOrderPrice.price)">
                        <p v-if="!isNil(row.lastOrderPrice.netPrice)">
                          {{ formatMoneyShekels(row.lastOrderPrice.netPrice) }}
                        </p>
                        <small v-if="row.lastOrderPrice.discount" class="d-block text-muted">
                          ({{
                            `${formatMoneyShekels(row.lastOrderPrice.price)}, ${formatPercent(
                              row.lastOrderPrice.discount
                            )}`
                          }})
                        </small>
                      </template>
                    </template>
                    <template v-else>-</template>
                  </td>
                  <td class="bg-secondary">
                    <template v-if="!isNil(row.currentTerm)">
                      <template v-if="!isNil(row.currentTerm.price)">
                        <p>{{ formatMoney(row.currentTerm.netPrice) }}</p>
                        <small v-if="row.currentTerm.discount" class="d-block text-muted">
                          ({{ `${formatMoney(row.currentTerm.price)}, ${formatPercent(row.currentTerm.discount)}` }})
                        </small>
                      </template>
                    </template>
                    <Tag v-else>{{ $t('terms.noTerm') }}</Tag>
                  </td>
                </tr>
              </tbody>
            </table>
          </template>
          <template #cell-name="{ rowData: { name, references } }">
            <template v-if="name && references.length">
              <div class="d-flex align-items-center">
                <AdditionalNamesPopOver
                  v-if="references.length > 1"
                  :icon-size="12"
                  :references="references"
                  :class="$direction === 'rtl' ? 'ms-1' : 'me-1'"
                />
                {{ name }}
              </div>
            </template>
          </template>
          <template #cell-restaurantName="{ rowData: { restaurantName, subTable } }">
            <template v-if="subTable">
              {{ `${restaurantName} ${$t('commons.restaurants')}` }}
            </template>
            <template v-else-if="restaurantName">{{ restaurantName }}</template>
            <template v-else>-</template>
          </template>
          <template #cell-currentTerm="{ rowData: { currentTerm } }">
            <template v-if="!isNil(currentTerm)">
              <template v-if="!isNil(currentTerm.price)">
                <p>{{ formatMoney(currentTerm.netPrice) }}</p>
                <small v-if="currentTerm.discount" class="d-block text-muted">
                  ({{ `${formatMoney(currentTerm.price)}, ${formatPercent(currentTerm.discount)}` }})
                </small>
              </template>
              <template v-else-if="currentTerm.priceIndexName !== null"> </template>
              <template v-if="typeof currentTerm === 'number'">
                <template v-if="currentTerm > 0">
                  {{ `${currentTerm} ${$t('commons.agreements')}` }}
                </template>
                <template v-else>-</template>
              </template>
            </template>
            <Tag v-else>{{ $t('terms.noTerm') }}</Tag>
          </template>
          <template #cell-lastOrderPrice="{ rowData: { lastOrderPrice, subTable } }">
            <template v-if="!isNil(lastOrderPrice)">
              <template v-if="!isNil(lastOrderPrice.price)">
                <p v-if="!isNil(lastOrderPrice.netPrice)">{{ formatMoneyShekels(lastOrderPrice.netPrice) }}</p>
                <small v-if="lastOrderPrice.discount" class="d-block text-muted">
                  ({{ `${formatMoneyShekels(lastOrderPrice.price)}, ${formatPercent(lastOrderPrice.discount)}` }})
                </small>
              </template>
              <template v-if="subTable">{{ `${lastOrderPrice} ${$t('commons.charges')}` }}</template>
            </template>
            <template v-else>-</template>
          </template>
          <template #cell-lastOrderDate="{ rowData: { lastOrderDate, subTable } }">
            <template v-if="subTable && lastOrderDate">{{ `${lastOrderDate} ${$t('commons.charges')}` }}</template>
            <template v-else-if="lastOrderDate">
              {{ formatDate(lastOrderDate) }}
            </template>
            <template v-else>-</template>
          </template>
          <template #cell-decisionTaking="{ rowIndex }">
            <el-form-item :prop="'products.' + rowIndex" :rules="{ required: true }" :show-message="false" class="m-0">
              <el-select
                v-model="formModel.products[rowIndex]"
                :placeholder="$t('commons.select')"
                :disabled="isTaskCompleted"
              >
                <el-option :label="$t('tasks.task.product.sameProduct')" :value="PRODUCT_MERGE_OPTION.SAME_PRODUCT" />
                <el-option
                  :label="$t('tasks.task.product.anotherProduct')"
                  :value="PRODUCT_MERGE_OPTION.ANOTHER_PRODUCT"
                />
              </el-select>
            </el-form-item>
          </template>
        </Table>
      </el-form>
    </div>
    <div class="d-flex justify-content-end">
      <Button type="secondary" @click="close">{{ $t('commons.cancel') }}</Button>
      <Button type="primary" :disabled="isTaskCompleted" @click="openConfirmationModal">{{
        $t('commons.proceed')
      }}</Button>
    </div>
    <ProductMergeTaskConfirmationModal
      v-if="confirmationModalOpen"
      :source-product="productToReviewTableData[0]"
      :products-to-merge="productsToMerge"
      @close="confirmationModalOpen = false"
      @productsMerged="completeTask"
    />
  </el-dialog>
</template>

<script>
import { computed, ref, watch, getCurrentInstance } from 'vue';
import { useMutation } from '@vue/apollo-composable';
import { isNil } from 'ramda';

import { AdditionalNamesIcon } from '@/assets/icons';
import { Table, Tag, Button } from '@/modules/core';
import { useProduct } from '@/modules/products/compositions/product';
import { useTerms } from '@/modules/products/compositions/terms';
import AdditionalNamesPopOver from '@/modules/products/components/AdditionalNamesPopOver';
import { calculateTerm } from '@/modules/purchase-management/calculation';
import {
  formatFloat2Digits,
  formatMoney,
  formatMoneyShekels,
  formatPercent,
  formatDate,
} from '@/modules/purchase-management/purchaseManagementFormatters';

import {
  useOrderItemAggregationByBusiness,
  GROUP_ORDER_ITEMS_BY_BUSINESS,
} from './compositions/useOrderItemAggregationByBusiness';
import ProductMergeTaskConfirmationModal from './components/ProductMergeTaskConfirmationModal';
import { TASK_COMPLETE_MUTATION } from './compositions/graphql';

const TABLE_HEADERS = {
  SUPPLIER: 'supplier',
  SKU: 'sku',
  NAME: 'name',
  RESTAURANT_NAME: 'restaurantName',
  LAST_ORDER_DATE: 'lastOrderDate',
  LAST_ORDER_PRICE: 'lastOrderPrice',
  CURRENT_TERM: 'currentTerm',
  DECISION_TAKING: 'decisionTaking',
};

const PRODUCT_MERGE_OPTION = {
  SAME_PRODUCT: 'sameProduct',
  ANOTHER_PRODUCT: 'anotherProduct',
};

const getProductPriceFromLastOrder = (productId, order) => {
  const product = order.products.find((orderProduct) => orderProduct.productId === productId);
  if (!product || isNil(product.price)) return null;

  return {
    netPrice: product.discount ? (product.price * (100 - product.discount)) / 100 : product.price,
    discount: product.discount,
    price: product.price,
  };
};

const buildTableRowData = (product, aggregation, terms) => {
  if (!aggregation && !terms.length) return {};

  const restaurant = aggregation?.business ?? terms.length ? terms[0]?.business : {};
  const lastOrder = aggregation?.lastOrder;

  return {
    productId: product.id,
    supplier: product.business.name,
    sku: product.sku,
    name: product.name,
    references: product.references,
    restaurantName: restaurant?.name,
    restaurantId: restaurant?.id,
    lastOrderDate: lastOrder?.date,
    lastOrderPrice: lastOrder ? getProductPriceFromLastOrder(product.id, lastOrder?.order) : null,
    currentTerm: calculateTerm(terms),
  };
};

const buildSubTable = (byBusinessData, productId) => {
  return Object.values(byBusinessData).map(({ aggregation, terms }) => {
    const restaurant = aggregation?.business ?? terms[0].business;
    const lastOrder = aggregation?.lastOrder;

    return {
      restaurantName: restaurant.name,
      lastOrderDate: lastOrder?.date,
      lastOrderPrice: lastOrder ? getProductPriceFromLastOrder(productId, lastOrder?.order) : null,
      currentTerm: calculateTerm(terms),
    };
  });
};

const buildMultipleRestaurantsRowData = (product, byBusinessData) => {
  const byBusinessDataArray = Object.values(byBusinessData);

  if (!byBusinessDataArray.length) return {};

  const ordersNum = byBusinessDataArray.reduce((sum, { aggregation }) => (aggregation?.lastOrder ? sum + 1 : sum), 0);
  const restaurantsWithProductTermNum = byBusinessDataArray.reduce(
    (sum, { terms }) => (terms.length ? sum + 1 : sum),
    0
  );

  const restaurantsNum = Math.max(ordersNum, restaurantsWithProductTermNum);
  const restaurant = byBusinessDataArray[0].aggregation?.business ?? byBusinessDataArray[0].terms[0].business;

  return {
    productId: product.id,
    supplier: product.business.name,
    sku: product.sku,
    name: product.name,
    references: product.references,
    restaurantName: restaurantsNum,
    restaurantId: restaurant.id,
    lastOrderDate: ordersNum ? ordersNum : null,
    lastOrderPrice: ordersNum ? ordersNum : null,
    currentTerm: restaurantsWithProductTermNum,
    expandable: true,
    expandableCustomClass: 'p-0',
    subTable: buildSubTable(byBusinessData, product.id),
  };
};

export default {
  components: { Table, Tag, Button, ProductMergeTaskConfirmationModal, AdditionalNamesIcon, AdditionalNamesPopOver },
  props: {
    productId: { type: String, required: true },
    taskId: { type: String, required: true },
    isTaskCompleted: Boolean,
  },
  setup(props) {
    const root = getCurrentInstance().proxy;
    const productId = computed(() => props.productId);

    const { mutate: completeTaskMutation } = useMutation(TASK_COMPLETE_MUTATION);

    const { product: productToReview, loading: productLoading } = useProduct(productId);
    const {
      aggregation: productToReviewGroupByBusinessAggregations,
      loading: productToReviewGroupByBusinessAggregationLoading,
    } = useOrderItemAggregationByBusiness({ productId });
    const productToReviewGroupByBusinessAggregation = computed(
      () =>
        [...productToReviewGroupByBusinessAggregations.value].sort(
          (aggregation1, aggregation2) => aggregation2.lastOrder.date - aggregation1.lastOrder.date
        )[0] ?? {}
    );

    const { terms: productToReviewCurrentTerms, loading: productToReviewTermLoading } = useTerms(
      computed(() => [productId.value])
    );

    const productToReviewTableData = computed(() => [
      buildTableRowData(
        productToReview.value,
        productToReviewGroupByBusinessAggregation.value,
        productToReviewCurrentTerms.value.filter((term) => term.active)
      ),
    ]);

    const similarProducts = computed(() => productToReview.value?.similarProducts);

    const { terms: similarProductsTerms, loading: similarProductsTermsLoading } = useTerms(
      computed(() => [...new Set(similarProducts.value.map((similarProduct) => similarProduct.id))])
    );

    const similarProductsAggregations = ref([]);
    const similarProductsAggregationsLoading = ref(false);

    watch(
      similarProducts,
      () => {
        similarProductsAggregationsLoading.value = true;
        similarProductsAggregations.value = [];
        Promise.all(
          similarProducts.value.map((similarProduct) => {
            return root.$apollo
              .query({ query: GROUP_ORDER_ITEMS_BY_BUSINESS, variables: { productId: similarProduct.id } })
              .then(({ data }) => data.orderAggregation.groupOrderItemsByBusiness);
          })
        )
          .then((data) => (similarProductsAggregations.value = data))
          .catch(console.error)
          .finally(() => (similarProductsAggregationsLoading.value = false));
      },
      { immediate: true }
    );

    const similarProductsLoading = computed(
      () => similarProductsTermsLoading.value || similarProductsAggregationsLoading.value
    );

    const productsByBusinessData = computed(() => {
      return similarProducts.value.map((product, index) => {
        const byBusiness = {};
        (similarProductsAggregations.value[index] || []).forEach(
          (aggregation) => (byBusiness[aggregation.business.id] = { aggregation, terms: [] })
        );
        similarProductsTerms.value
          .filter((term) => term.active)
          .forEach((term) => {
            if (term.productId !== product.id) return;
            const businessData = byBusiness[term.businessId] || { terms: [] };
            businessData.terms.push(term);
            byBusiness[term.businessId] = businessData;
          });
        return byBusiness;
      });
    });

    const transformedSimilarProducts = computed(() =>
      productsByBusinessData.value
        .map((byBusiness, index) => {
          const similarProduct = similarProducts.value[index];

          if (Object.values(byBusiness).length > 1) {
            return buildMultipleRestaurantsRowData(similarProduct, byBusiness);
          } else if (Object.values(byBusiness).length === 1) {
            const businessData = Object.values(byBusiness)[0];
            return buildTableRowData(similarProduct, businessData.aggregation, businessData.terms);
          }
          return buildTableRowData(similarProduct, {}, []);
        })
        .flat()
    );

    const formModel = ref({
      products: [],
    });

    watch(
      transformedSimilarProducts,
      () => (formModel.value.products = transformedSimilarProducts.value.map(() => null)),
      { immediate: true }
    );

    return {
      isNil,
      PRODUCT_MERGE_OPTION,
      loading: computed(
        () =>
          productLoading.value ||
          productToReviewGroupByBusinessAggregationLoading.value ||
          productToReviewTermLoading.value ||
          similarProductsLoading.value
      ),
      formModel,
      productToReview,
      productToReviewTableData,
      transformedSimilarProducts,
      productsByBusinessData,
      completeTaskMutation,
    };
  },
  data() {
    return {
      confirmationModalOpen: false,
      productsToMerge: [],
    };
  },
  computed: {
    columns() {
      return [
        {
          header: this.$t('commons.supplier'),
          key: TABLE_HEADERS.SUPPLIER,
          width: '13%',
        },
        { header: this.$t('commons.sku'), key: TABLE_HEADERS.SKU, width: '10%' },
        { header: this.$t('tasks.task.product.table.productName'), key: TABLE_HEADERS.NAME, width: '14%' },
        {
          header: this.$t('tasks.task.product.table.showedInRestaurant'),
          key: TABLE_HEADERS.RESTAURANT_NAME,
          width: '14%',
        },
        {
          header: this.$t('tasks.task.product.table.lastOrderDate'),
          key: TABLE_HEADERS.LAST_ORDER_DATE,
        },
        {
          header: this.$t('tasks.task.product.table.lastOrderPrice'),
          key: TABLE_HEADERS.LAST_ORDER_PRICE,
        },
        {
          header: this.$t('tasks.task.product.table.currentAgreement'),
          key: TABLE_HEADERS.CURRENT_TERM,
          width: '9%',
        },
        {
          header: this.$t('tasks.task.product.table.decisionMaking'),
          key: TABLE_HEADERS.DECISION_TAKING,
          width: '10%',
        },
      ];
    },
  },
  methods: {
    close() {
      this.$emit('close');
    },
    completeTask() {
      this.completeTaskMutation({ taskId: this.taskId });
      this.close();
    },
    openConfirmationModal() {
      this.$refs.form.validate((valid) => {
        if (valid) {
          this.productsToMerge = this.formModel.products
            .map((productOption, index) => {
              return productOption === PRODUCT_MERGE_OPTION.SAME_PRODUCT
                ? this.transformedSimilarProducts[index]
                : null;
            })
            .filter((product) => !!product);

          if (this.productsToMerge.length) {
            this.confirmationModalOpen = true;
          } else {
            this.completeTask();
          }
        }
      });
    },
    formatMoneyShekels(number) {
      return formatMoneyShekels(number) ?? '-';
    },
    formatMoney(number) {
      return formatMoney(number) ?? '-';
    },
    formatPercent(number) {
      return formatPercent(number) ?? '-';
    },
    formatFloat2Digits(number) {
      return formatFloat2Digits(number) ?? '-';
    },
    formatDate(ms) {
      return ms ? formatDate(ms) : '-';
    },
  },
};
</script>

<style scoped lang="scss">
.sub-table {
  td:first-of-type {
    width: 40%;
  }
  td:nth-of-type(2) {
    width: 14%;
  }
  td:nth-of-type(3) {
    width: 13.5%;
  }
  td:nth-of-type(4) {
    width: 13.5%;
  }
}
</style>
